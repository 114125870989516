import React, { useCallback, useRef, useState } from "react";

export default function Biography() {
  const observer = useRef();
  const indicatorRef = useRef();

  const [TabIndex, setTabIndex] = useState(0);
  const [Viewing, setViewing] = useState(false);

  const biographySection = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setViewing(true);
      }
    });
    if (node) observer.current.observe(node);
  });

  const handleBiographyChangeTab = (index) => {
    setTabIndex(index);
    indicatorRef.current.style.top = `${index * 44 + 40}px`;
  };

  const handleEducationChangeTab = (index) => {
    setTabIndex(index);
    indicatorRef.current.style.top = `${index * 44 + 100}px`;
  };

  return (
    <section
      id="biography-section"
      className="relative w-full bg-primary flex flex-col items-center justify-center"
    >
      <div
        className={`w-full grid max-w-[1280px] gap-8 py-10 px-5 mx-auto rounded-lg md:px-12 lg:px-16 xl:px-32 bg-transparent text-gray-100 transition-all duration-1000 ${
          Viewing ? "opacity-1 translate-y-0" : "opacity-0 translate-y-52"
        }`}
      >
        <h2 className="text-zinc-500 w-full">// Biography</h2>
        <div
          ref={biographySection}
          className="w-full flex flex-col sm:flex-row justify-start gap-5 items-start mt-10 overflow-hidden"
        >
          <div
            className={`w-full h-fit max-w-full sm:max-w-[220px] overflow-x-auto`}
          >
            <div className="relative w-fit max-w-fit sm:min-w-[220px] sm:max-w-[220px] sm:w-[220px] flex flex-row overflow-x-scroll hidden-scrollbar sm:overflow-hidden overflow-y-hidden sm:flex-col items-start text-white/80 border-l-0 sm:border-l-2 border-primary_dark">
              <div
                ref={indicatorRef}
                className={`hidden sm:block absolute w-1 transition-all top-10 -left-[1px] h-11 bg-secondary`}
              ></div>
              <h2 className="relative text-secondary px-5 py-2 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary before:mt-2">
                Experience
              </h2>
              <button
                className={`py-2.5 w-fit sm:w-full text-left px-4 sm:px-2 rounded-md sm:rounded-r-md transition-all whitespace-nowrap overflow-hidden overflow-ellipsis max-w-fit sm:max-w-full ${
                  TabIndex == 0
                    ? "text-secondary bg-primary_dark/70"
                    : "hover:text-secondary/80 hover:translate-x-0.5 hover:-translate-y-0.5 "
                }`}
                onClick={() => handleBiographyChangeTab(0)}
              >
                Be Wireless Solutions
              </button>
              <button
                className={`py-2.5 w-fit sm:w-full text-left px-4 sm:px-2 rounded-md sm:rounded-r-md transition-all whitespace-nowrap overflow-hidden overflow-ellipsis max-w-fit sm:max-w-full ${
                  TabIndex == 1
                    ? "text-secondary bg-primary_dark/70"
                    : "hover:text-secondary/80 hover:translate-x-0.5 hover:-translate-y-0.5 "
                }`}
                onClick={() => handleBiographyChangeTab(1)}
              >
                Be Wireless Solutions
              </button>
              <button
                className={`py-2.5 w-fit sm:w-full text-left px-4 sm:px-2 rounded-md sm:rounded-r-md transition-all whitespace-nowrap overflow-hidden overflow-ellipsis max-w-fit sm:max-w-full ${
                  TabIndex == 2
                    ? "text-secondary bg-primary_dark/70"
                    : "hover:text-secondary/80 hover:translate-x-0.5 hover:-translate-y-0.5 "
                }`}
                onClick={() => handleBiographyChangeTab(2)}
              >
                Dentrain UAE
              </button>
              <button
                className={`py-2.5 w-fit sm:w-full text-left px-4 sm:px-2 rounded-md sm:rounded-r-md transition-all whitespace-nowrap overflow-hidden overflow-ellipsis max-w-fit sm:max-w-full ${
                  TabIndex == 3
                    ? "text-secondary bg-primary_dark/70"
                    : "hover:text-secondary/80 hover:translate-x-0.5 hover:-translate-y-0.5 "
                }`}
                onClick={() => handleBiographyChangeTab(3)}
              >
                Quick Medical Services
              </button>
              <h2 className="relative text-secondary px-5 py-2 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary before:mt-2 sm:mt-5">
                Education
              </h2>
              <button
                className={`py-2.5 w-fit sm:w-full text-left px-4 sm:px-2 rounded-md sm:rounded-r-md transition-all whitespace-nowrap overflow-hidden overflow-ellipsis max-w-fit sm:max-w-full ${
                  TabIndex == 4
                    ? "text-secondary bg-primary_dark/70"
                    : "hover:text-secondary/80 hover:translate-x-0.5 hover:-translate-y-0.5 "
                }`}
                onClick={() => handleEducationChangeTab(4)}
              >
                Ecole Polytechnique Sousse
              </button>
              <button
                className={`py-2.5 w-fit sm:w-full text-left px-4 sm:px-2 rounded-md sm:rounded-r-md transition-all whitespace-nowrap overflow-hidden overflow-ellipsis max-w-fit sm:max-w-full ${
                  TabIndex == 5
                    ? "text-secondary bg-primary_dark/70"
                    : "hover:text-secondary/80 hover:translate-x-0.5 hover:-translate-y-0.5 "
                }`}
                onClick={() => handleEducationChangeTab(5)}
              >
                ISSAT Sousse
              </button>
            </div>
          </div>
          {TabIndex == 0 && (
            <div className="w-full simple-fade-in-animation p-4 min-h-[250px]">
              <h3>
                Summer internship as a Mobile Developer{" "}
                <span className="text-secondary">@ Be Wireless Solutions</span>
              </h3>
              <p className="text-sm text-zinc-400 mt-1 mb-6">
                August - September 2024
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                Developed a mobile application for maintenance staff to create
                and manage interventions using Ionic, React, TypeScript, TailwindCSS,
                Node.js, Express.js and MongoDB.
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                Implemented functionality that allows maintenance staff to log
                in, view monthly statistics, add new interventions, and capture
                photos for reports.
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                Created a web-based back office for administrators, enabling
                them to manage maintenance staff accounts, view statistics and
                data through dynamic charts, and monitor interventions in
                real-time.
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                Improved operational efficiency by streamlining the intervention
                tracking process and providing a user-friendly platform for both
                maintenance staff and administrators.
              </p>
              <div className="w-full flex flex-wrap items-center mt-7 gap-2">
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Ionic
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  ReactJS
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Tailwind CSS
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  DevExtreme
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Node.js
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Express.js
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  MongoDB
                </p>
              </div>
            </div>
          )}
          {TabIndex == 1 && (
            <div className="w-full simple-fade-in-animation p-4 min-h-[250px]">
              <h3>
                Summer internship as a Full-Stack Developer{" "}
                <span className="text-secondary">@ Be Wireless Solutions</span>
              </h3>
              <p className="text-sm text-zinc-400 mt-1 mb-6">
                July - August 2023
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                Built a tank management and tracking application from scratch
                using Typescript, AngularJS, Tailwind CSS, Quarkus, Keycloak,
                Leaflet, and PostgreSQL.
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                The application facilitated real-time data collection from
                sensors on tanks and pumps, improving inventory management for
                gas stations and preventing issues through an alerts system.
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                Enhanced operational efficiency and reduced manual errors by
                automating the monitoring process.
              </p>
              <div className="w-full flex flex-wrap items-center mt-7 gap-2">
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Typescript
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Angular
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Tailwind CSS
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  DevExtreme
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Quarkus
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Keycloak
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Leaflet
                </p>
              </div>
            </div>
          )}
          {TabIndex == 2 && (
            <div className="w-full simple-fade-in-animation p-4 min-h-[250px]">
              <h3>
                Full-Stack Developer{" "}
                <span className="text-secondary">@ Dentrain UAE</span>
              </h3>
              <p className="text-sm text-zinc-400 mt-1 mb-6">
                July - August 2022
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                Designed and developed an e-commerce website for selling medical
                scrubs, incorporating ReactJS, Tailwind CSS, JavaScript,
                Typescript, EmailJS, Node.js, MongoDB, Express.js, PassportJS,
                and Stripe for payment processing.
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                The website's e-commerce functionality enabled online
                transactions, increasing the client's sales and operational
                efficiency.
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                Provided continuous maintenance and updates to ensure the
                website's performance and security.
              </p>
              <div className="w-full flex flex-wrap items-center mt-7 gap-2">
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Typescript
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  ReactJS
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Tailwind CSS
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Express.js
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  MongoDB
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  PassportJS
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Stripe
                </p>
              </div>
            </div>
          )}
          {TabIndex == 3 && (
            <div className="w-full simple-fade-in-animation p-4 min-h-[250px]">
              <h3>
                End of studies internship as a Full-Stack Developer{" "}
                <span className="text-secondary">@ Quick Medical Services</span>
              </h3>
              <p className="text-sm text-zinc-400 mt-1 mb-6">
                February - May 2022
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                Developed and maintained a comprehensive website for selling
                medical and surgical instruments using Next.js, ReactJS,
                Tailwind CSS, JavaScript, Node.js, MongoDB, Next-Auth.js, and
                EmailJS.
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                The website allowed users to browse a wide range of medical and
                surgical instruments, place orders online, and request
                quotations, which streamlined the client's sales process.
              </p>
              <p className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3">
                Successfully transitioned the client from traditional
                transaction methods to an efficient online system, significantly
                improving sales operations and customer engagement.
              </p>
              <div className="w-full flex flex-wrap items-center mt-7 gap-2">
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Next.js
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  ReactJS
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Tailwind CSS
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Node.js
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  MongoDB
                </p>
                <p className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit">
                  Next-Auth.js
                </p>
              </div>
            </div>
          )}
          {TabIndex == 4 && (
            <div className="w-full simple-fade-in-animation p-4 min-h-[250px]">
              <h3>
                Software Engineering Degree{" "}
                <span className="text-secondary">
                  @ Ecole Polytechnique Sousse
                </span>
              </h3>
              <p className="text-sm text-zinc-400 mt-1 mb-6">2022 - Present</p>
            </div>
          )}
          {TabIndex == 5 && (
            <div className="w-full simple-fade-in-animation p-4 min-h-[250px]">
              <h3>
                Bachelor of Computer Science{" "}
                <span className="text-secondary">
                  @ Higher Institute of Applied Science and Technology of Sousse
                </span>
              </h3>
              <p className="text-sm text-zinc-400 mt-1 mb-6">2019 - 2022</p>
            </div>
          )}
        </div>
      </div>
      <div className="h-[3px] opacity-90 absolute -bottom-20 left-0 right-0 mx-auto w-32 rounded-md bg-secondary seperation-animation-1"></div>
      <div className="absolute h-[3px] rounded-md -bottom-14 right-0 left-0 mx-auto w-48 bg-secondary opacity-90 seperation-animation-2"></div>
      <div className="h-[3px] absolute opacity-90 -bottom-8 left-0 right-0 w-40 mx-auto rounded-md bg-secondary seperation-animation-3"></div>
    </section>
  );
}
