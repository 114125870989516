import React, { useCallback, useRef, useState } from "react";

export default function Introduction() {
  const [Viewing, setViewing] = useState(false);

  const observer = useRef();
  const introductionSection = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setViewing(true);
      }
    });
    if (node) observer.current.observe(node);
  });

  return (
    <>
      <section
        id="introduction-section"
        className={`w-full bg-primary_dark shadow-card flex flex-col items-center justify-center pt-24 pb-60`}
      >
        <div
          ref={introductionSection}
          className={`max-w-[1480px] w-11/12 overflow-visible mt-5 transition-all duration-1000 flex flex-wrap items-start gap-10 justify-around ${
            Viewing ? "opacity-1 translate-y-0" : "opacity-0 translate-y-52"
          } `}
        >
          <div className="flex flex-col items-start gap-5 w-full lg:w-3/6">
            <h2 className="text-zinc-500 w-fit px-10 lg:px-0 ">
              // Introduction
            </h2>
            <p
              className="text-gray-200 whitespace-normal text-sm md:text-xl text-justify"
              style={{ lineHeight: "2.2rem" }}
            >
              Oussama is a full-stack web developer whose passion for computer
              science drives him to consistently deliver cutting-edge websites
              and web applications. He is determined to continuously excel in
              his work and to stand out from the crowd. Helping people by
              sharing his knowledge is his favorite way to learn more about
              himself and to further develop his skills.
            </p>
          </div>

          <div className="flex flex-col gap-5 items-start w-full lg:w-2/6">
            <h2 className="text-zinc-500 w-fit px-10 lg:px-0">// Skills</h2>
            <div className="text-fourth flex flex-row items-start justify-start gap-2 flex-wrap">
              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648512/html_pxk3rn.svg"
                  alt="HTML icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  HTML
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648507/css_gyaf7z.svg"
                  alt="CSS icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  CSS
                </span>
              </div>

              <div className="w-11 h-11 z-10 mb-4 rounded-full bg-transparent relative p-1.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648512/js_imr3bo.svg"
                  alt="JavaScript icon"
                  width={35}
                  height={35}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  JavaScript
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1683426114/icons8-php-48_vernow.png"
                  alt="PHP icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  PHP
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/python.png"
                  alt="Python icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Python
                </span>
              </div>

              <div className="w-12 h-12 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/java.png"
                  alt="Java icon"
                  width={45}
                  height={45}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Java
                </span>
              </div>

              <div className="w-11 h-11 z-10 mb-4 rounded-full bg-transparent relative p-1 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648518/ts_t6ipoc.svg"
                  alt="Typescript icon"
                  width={35}
                  height={35}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Typescript
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648513/next_csitru.svg"
                  alt="Next.js icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Next.js
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648515/react_ak0sla.svg"
                  alt="ReactJS icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  ReactJS
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/ionic.png"
                  alt="Ionic icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Ionic
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648517/tailwind_k1zaze.png"
                  alt="Tailwind CSS icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-24 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Tailwind CSS
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648514/node_nj2kde.svg"
                  alt="Node.js icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Node.js
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648512/express_n1saii.png"
                  alt="Express.js icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Express.js
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/angular.png"
                  alt="Angular icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  AngularJS
                </span>
              </div>

              <div className="w-11 h-11 z-10 mb-4 rounded-full bg-transparent relative px-1.5 py-1.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/chartjs.png"
                  alt="Chart.js icon"
                  width={35}
                  height={35}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Chart.js
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/Threejs.png"
                  alt="Three.js icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Three.js
                </span>
              </div>

              <div className="w-11 h-11 z-10 mb-4 rounded-full bg-transparent relative p-1 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1670160372/icons8-stripe-48_egmca6.png"
                  alt="Stripe icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Stripe
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1670160372/icons8-json-web-token-48_ujle0l.png"
                  alt="JWT icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  JWT
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/prisma.png"
                  alt="Prisma icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Prisma
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648514/mongo_uqpcud.svg"
                  alt="MongoDB icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  MongoDB
                </span>
              </div>

              <div className="w-11 h-11 z-10 mb-4 rounded-full bg-transparent relative  hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648509/firebase_ztz5rt.png"
                  alt="Firebase icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Firebase
                </span>
              </div>

              <div className="w-11 h-11 z-10 mb-4 rounded-full bg-transparent relative hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1683493768/pfe/mysql_n3hwqg.png"
                  alt="MySQL icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  MySQL
                </span>
              </div>

              <div className="w-11 h-11 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/postgres.png"
                  alt="PostgreSQL icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  PostgreSQL
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1640648510/git_b23wnr.svg"
                  alt="Git icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Git
                </span>
              </div>

              <div className="w-11 h-11 z-10 mb-4 rounded-full bg-transparent relative p-1 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/laravel.png"
                  alt="Laravel icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Laravel
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/symfony.png"
                  alt="Symfony icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Symfony
                </span>
              </div>

              <div className="w-12 h-12 z-10 mb-4 rounded-full bg-transparent relative p-1.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1683426735/codeigniter_evfxab.png"
                  alt="CodeIgniter 4 icon"
                  width={35}
                  height={35}
                />
                <span className="absolute text-center p-1 w-24 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  CodeIgniter 4
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="https://res.cloudinary.com/dwvwjxizk/image/upload/v1683426114/icons8-wordpress-48_zhcr2l.png"
                  alt="Wordpress icon"
                  width={40}
                  height={40}
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Wordpress
                </span>
              </div>

              <div className="w-9 h-9 z-10 mb-4 rounded-full bg-transparent relative px-1 py-1 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/django.png"
                  alt="Django icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Django
                </span>
              </div>

              <div className="w-9 h-9 sm:w-10 sm:h-10 md:w-11 md:h-11 z-10 mb-4 rounded-full bg-transparent relative px-0.5 py-0.5 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/leaflet.png"
                  alt="Leaflet icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Leaflet
                </span>
              </div>

              <div className="w-11 h-11 z-10 mb-4 rounded-full bg-transparent relative p-1 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/keycloak.png"
                  alt="Keycloak icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Keycloak
                </span>
              </div>

              <div className="w-11 h-11 z-10 mb-4 rounded-full bg-transparent relative p-1 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/sb.png"
                  alt="Spring Boot icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Spring Boot
                </span>
              </div>

              <div className="w-11 h-11 z-10 mb-4 rounded-full bg-transparent relative p-1 hover:scale-110 hover:cursor-pointer group transition-all hover:z-20">
                <img
                  src="/skills/quarkus.svg"
                  alt="Quarkus icon"
                  width={40}
                  height={40}
                  id="services-section"
                />
                <span className="absolute text-center p-1 w-20 top-[110%] left-1/2 -translate-x-1/2 z-[9999] origin-bottom-top rounded-lg shadow-md text-white bg-gray-900 text-xs f transition-all duration-100 hidden group-hover:block">
                  Quarkus
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
