import { IconContext } from "react-icons";
import { TbBrandFiverr } from "react-icons/tb";
import { FiGithub } from "react-icons/fi";
import { SiUpwork } from "react-icons/si";
import { FaLinkedinIn } from "react-icons/fa";



export default function Hero() {
  return (
    <section className="w-full max-w-[1480px] min-h-[800px] px-10 lg:px-20 pt-28 pb-14 md:pt-48 md:pb-40 flex justify-start items-center gap-10 md:gap-5 flex-wrap md:flex-nowrap xl:gap-[10%]">
      <div className="relative w-full max-w-2xl text-white flex flex-col items-center md:items-start font-semibold lg:font-bold">
        <div className="max-w-full w-fit">
          <p className="text-base md:text-xl z-10 mb-2 lg:mb-5 text-secondary">
            Hi, I'm
          </p>
          <h2 className="z-10 relative text-3xl lg:text-4xl after:absolute after:top-[110%] after:left-0 after:h-0.5 after:w-40 after:bg-secondary text-gray-200  whitespace-nowrap">
            Oussama Dhraief
          </h2>
          <h2 className="z-10 relative text-2xl lg:text-3xl mt-7 text-gray-400  sm:whitespace-nowrap">
            And I'm a full-stack developer..
          </h2>
          <p className="mt-7 mb-5 max-w-2xl md:my-7 lg:my-8 text-xs font-medium md:text-sm lg:text-lg text-gray-500">
            <span className="text-secondary/80 text-sm lg:text-base">&#123;" </span>with a passion for crafting captivating user experiences. Right now, I'm fully focused on mastering front-end development where every line of code adds its own unique flair to the web.
            <span className="text-secondary/80 text-sm lg:text-base "> "&#125;</span>
          </p>
        </div>
        <div className="max-w-[386px] w-full flex justify-center md:justify-start  lg:justify-center items-center gap-3 md:gap-4 lg:gap-5 mt-6">
          <div className="w-fit h-fit bg-secondary rounded overflow-visible">
            <a
              href="/Oussama-Dhraief-Resume.pdf"
              target="_blank"
              className="w-fit text-primary bg-secondary px-3 py-1 md:px-4 md:py-1.5 lg:px-8 lg:py-2.5 text-xs md:text-sm font-semibold lg:font-bold flex gap-3 items-center transition-all hover:translate-x-0.5 hover:-translate-y-0.5 whitespace-nowrap cursor-pointer border rounded border-secondary hover:border-l-primary hover:border-b-primary"
            >
              Resume
            </a>
          </div>

          <div className="w-fit h-fit bg-secondary overflow-visible rounded">
            <a
              href="#contact-section"
              className="text-secondary bg-primary px-4 py-1 md:px-6 md:py-1.5 lg:px-8 lg:py-2.5 text-xs md:text-sm font-semibold lg:font-bold flex gap-3 items-center transition-all hover:translate-x-0.5 hover:-translate-y-0.5 whitespace-nowrap cursor-pointer border rounded border-secondary"
            >
              Hire me &#8594;
            </a>
          </div>
        </div>
        <div className="w-full max-w-[400px] z-10 mt-8 flex gap-0 md:gap-20 lg:gap-0 justify-evenly md:justify-start lg:justify-evenly items-center">
          

          <a
            href="https://www.fiverr.com/oussama_dhraief"
            target="_blank"
            className="z-10 hover:-translate-y-0.5 transition-all"
          >
            <IconContext.Provider
              value={{ className: "w-5 h-5 md:h-6 md:w-6" }}
            >
              <TbBrandFiverr />
            </IconContext.Provider>
          </a>

          <a
            href="https://www.upwork.com/freelancers/~01b48cfe590f4e1aeb?mp_source=share"
            target="_blank"
            className="z-10 hover:-translate-y-0.5 transition-all"
          >
            <IconContext.Provider
              value={{ className: "w-5 h-5 md:h-6 md:w-6" }}
            >
              <SiUpwork />
            </IconContext.Provider>
          </a>

          <a
            href="https://www.linkedin.com/in/oussama-dhraief/"
            target="_blank"
            className="z-10 hover:-translate-y-0.5 transition-all"
          >
            <IconContext.Provider
              value={{ className: "w-5 h-5 md:h-5 md:w-5" }}
            >
              <FaLinkedinIn />
            </IconContext.Provider>
          </a>

          <a
            href="https://github.com/oussamadhraief"
            target="_blank"
            className="z-10 hover:-translate-y-0.5 transition-all"
          >
            <IconContext.Provider
              value={{ className: "w-5 h-5 md:h-6 md:w-6" }}
            >
              <FiGithub />
            </IconContext.Provider>
          </a>
        </div>
      </div>
      <div className="w-full flex justify-center md:max-w-[50%] md:w-1/2">
        <div className="relative w-60 xl:w-80 aspect-square rounded-full border-[3px] border-secondary border-dashed p-5  hover:scale-105 cursor-pointer transition-all duration-300 shadow-2xl">
          <img src="/me.jpg" alt="" className="rounded-full absolute top-[2.5%] left-[2.5%] w-[95%] h-[95%] shadow-2xl cursor-pointer" />
        </div>
      </div>
      {/* <div className="w-full max-w-[400px] md:max-w-[50%] md:w-1/2">
        <div className="w-full min-w-[350px] lg:min-w-[400px]">
          <lottie-player
            src="https://assets8.lottiefiles.com/packages/lf20_tno6cg2w.json"
            background="transparent"
            speed="1"
            style={{ width: "100%", aspectRatio: "1/1" }}
            loop
            autoplay
          ></lottie-player>
        </div>
      </div> */}
    </section>
  );
}
